import React, { Component } from 'react'
import TemplateWrapper from '../components/layout'
import Title from '../components/title'

class NotFoundPage extends Component {
  render() {
    return (
      <TemplateWrapper {...this.props}>
        <div>
          <div className="r3__section r3--no-head">
            <div className="r3__content">
              <Title text={'NOT FOUND'} />
              <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
          </div>
        </div>
      </TemplateWrapper>
    )
  }
}

export default NotFoundPage
